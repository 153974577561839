import { useSearchParams } from "next/navigation";
import { findStyleNameByIdAndLocale } from "../shared/generators/generators";
import { useStyleSelectModal } from "../shared/style-select-modal";

type StyleToggleProps = {
  styleParam: string;
  updateStyleParam: (newStyle: any) => void;
  translations: any;
};
export default function StyleToggle({ styleParam, updateStyleParam, translations }: StyleToggleProps) {
  const searchParams = useSearchParams();
  const { StyleSelectModal, setShowStyleSelectModal } = useStyleSelectModal(updateStyleParam, translations);

  return (
    <>
      <StyleSelectModal />
      <button
        // onClick={handleToggle}
        onClick={() => {
          setShowStyleSelectModal(true);
        }}
        type="button"
        className={`${
          styleParam !== "all" ? "border-[#DD0772] bg-[#DD0772]" : "border-[#3C3C3C] bg-[#2B2D31]"
        } mr-3 whitespace-nowrap rounded-md border-[0.5px] px-4 py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300`}
      >
        {styleParam === "all" ? (
          <>{translations.styleH1Specific}</>
        ) : (
          <>
            {translations.styleH1Singular}:{" "}
            {findStyleNameByIdAndLocale({
              id: searchParams.get("style") || "studio",
              locale: translations.key,
            })}
          </>
        )}
      </button>
    </>
  );
}
