import { ValidLocale } from "i18n";
import { generators } from "../shared/generators/generators";

interface Props {
  style: string[];
  sortBy?: string;
  pathCode: ValidLocale;
  queryParam: any;
  contentType: string;
  translations: any;
}

export const CategoryText = ({
  sortBy = "hot",
  style = ["all"],
  pathCode,
  queryParam,
  contentType,
  translations
}: Props) => {
  const isGifMode = contentType === "gif";

  // Helper function to generate style-related text
  const getStyleLabel = (styleArray: any, queryParam: any) => {
    if (queryParam) {
      let SEOText = translations.postSEOTitle;
      return SEOText;
    } else if (styleArray[0] === "all") {
      // Use the specific locale entry for 'all'
      return `${translations.aiPornH1}${translations.allH1}${translations.styleH1Plural}`;
    } else if (styleArray.length > 1) {
      return `${translations.aiPornH1}${styleArray
        .map((styleId: string) => {
          const genre = generators.find((gen) => gen.id === styleId);
          const labelObj = genre?.styleName.find((langObj) => langObj.name === translations.key);
          return labelObj ? labelObj.value : "No Label";
        })
        .join(", ")}`;
    } else {
      const genre = generators.find((gen) => gen.id === styleArray[0]);
      const labelObj = genre?.styleName.find((langObj) => langObj.name === translations.key);
      return `${translations.aiPornH1}${labelObj ? labelObj.value : "No Label"}`;
    }
  };

  function capitalizeWords(str: any): any {
    return str
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const buildText = () => {
    // If in GIF mode, return simplified text
    if (isGifMode) {
      switch (sortBy) {
        case "hot":
          return "Trending AI Porn Gifs";
        case "new":
          return "Newest AI Porn Gifs";
        default:
          return null;
      }
    }

    // Generating the heading based on sortBy
    switch (sortBy) {
      case "hot": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${translations.hotH1} ${
          queryParam ? `${capitalizeWords(queryParam)} ` : ""
        }${getStyleLabel(style, queryParam)}${
          style.length > 1 && !queryParam
            ? translations.styleH1Plural
            : style[0] === "all" || queryParam
            ? ""
            : translations.styleH1Singular
        }`;
      case "new": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${translations.newH1} ${
          queryParam ? `${capitalizeWords(queryParam)} ` : ""
        }${getStyleLabel(style, queryParam)}${
          style.length > 1 && !queryParam
            ? translations.styleH1Plural
            : style[0] === "all" || queryParam
            ? ""
            : translations.styleH1Singular
        }`;
      case "top": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${translations.topH1} ${
          queryParam ? `${capitalizeWords(queryParam)} ` : ""
        }${getStyleLabel(style, queryParam)}${
          style.length > 1 && !queryParam
            ? translations.styleH1Plural
            : style[0] === "all" || queryParam
            ? ""
            : translations.styleH1Singular
        }`;
      default:
        return null;
    }
  };

  return <h1 className="mb-4 text-lg font-bold text-white sm:text-xl">{buildText()}</h1>;
};
