"use client";
import { useRef, useEffect, useState, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";
import { gifReady } from "../shared/generators/generators";
import axiosInstance from "@/lib/axios/axiosInstance";
import { CategoryText } from "../shared/CategoryText";
import { TopTabLink } from "../shared/topTabLink";
import { useRouter } from "next/navigation";
import StyleToggle from "../shared/styleToggle";
import { TabLink } from "../shared/tabLink";
import { InfiniteScrollWrapper } from "../shared/infiniteScroll/infiniteScroll";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface PageData {
  info: {
    count: number;
    next: string | null;
    pages: number;
    prev: string | null;
  };
  results: Post[];
}

export function HomePage({
  contentType,
  modalTranslations,
  translations,
  signInModalTranslations,
  styleToggleTranslations,
}: {
  contentType: string;
  modalTranslations: any;
  translations: any;
  signInModalTranslations: any;
  styleToggleTranslations: any;
}) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  // State hooks for current tab, generator, and scroll visibility
  const [currTab, setCurrTab] = useState(searchParams.get("filter") || "hot");

  // Ref for the scrollable container
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Extract the 'style' query parameter
  const styleParam = searchParams.get("style") || "all";

  // Check if 'styleParam' contains a comma
  const processedStyleParam = styleParam.includes(",") ? "all" : styleParam;

  const [currGenerator, setCurrGenerator] = useState(processedStyleParam);

  // Labels for TobTabLink
  const options = [
    { key: "top2", label: "2 Days Top" },
    { key: "top7", label: "7 Days Top" },
    { key: "top15", label: "15 Days Top" },
  ];

  const setCurrGeneratorFromStr = (currGenStr: string) => {
    setCurrGenerator(currGenStr);
  };

  // Derived states
  const newURL = translations.path + (contentType === "gif" ? "gifs" : ""); //constructBaseURL(defaultLang, defaultCountry);
  const baseEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/collection`;
  const baseCacheEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/feed`;
  const baseGifEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/gifs`;

  // Define URL construction function
  const constructUrl = (tab: string, generator: string, index: number): string => {
    if (contentType === "gif") {
      const baseParams = `include=userLikes`;
      return `${baseGifEndpoint}?type=${tab}&${baseParams}`;
    } else {
      const baseParams = `limit=10&include=userLikes&images=1&views=1&likes=1`;
      const generatorParam =
        generator !== "all" ? `&generator=1&generatorId=${encodeURIComponent(generator)}` : "";
      const tabParams: { [key: string]: string } = {
        new: `&timespan=0${index === 0 ? "&date=1" : ""}`,
        hot: `&timespan=1${index === 0 ? "&sort=top" : ""}`,
        top: `&timespan=0${index === 0 ? "&sort=top" : ""}`,
        top2: `&timespan=2${index === 0 ? "&sort=top" : ""}`,
        top7: `&timespan=7${index === 0 ? "&sort=top" : ""}`,
        top15: `&timespan=15${index === 0 ? "&sort=top" : ""}`,
      };

      if (!tabParams[tab]) {
        throw new Error("Invalid tab provided");
      }

      if (tab === "top2" || tab === "top7" || tab === "hot" || tab === "top15") {
        const baseCacheParams = `include=userLikes`;
        const generatorCacheParams =
          generator !== "all" ? `&generatorId=${encodeURIComponent(generator)}` : "";
        const typeCacheParams = `&type=${tab}`;
        return `${baseCacheEndpoint}?${baseCacheParams}${typeCacheParams}${generatorCacheParams}`;
      }

      let endpointResult = `${baseEndpoint}?${baseParams}${tabParams[tab]}${generatorParam}`;
      return endpointResult;
    }
  };

  const updateStyleParam = (newStyle: any) => {
    setCurrGenerator(newStyle);
  };

  return (
    <div className="relative">
      {gifReady && (
        <div className="mx-auto -mt-4 flex flex-col sm:-mt-6">
          <div className="flex flex-row justify-center">
            <Link
              prefetch={false}
              href={{
                pathname: "/",
              }}
              className={`rounded-md text-sm font-bold ${
                contentType === "image" ? "text-white" : "text-gray-300"
              }
           `}
            >
              {translations.images || "Images"}
            </Link>
            <div className="mx-4 h-5 w-[1px] self-center bg-[#3C3C3C]" />
            <Link
              prefetch={false}
              href={{
                pathname: "/gifs",
              }}
              className={`rounded-md text-sm font-bold ${
                contentType === "gif" ? "text-white" : "text-gray-300"
              }
           `}
            >
              {translations.gifs || "GIFs"}
            </Link>
          </div>
          <div className="mb-4 mt-2 w-[100vw] border-b-[0.5px] border-[#3C3C3C]" />
        </div>
      )}
      <div className="mx-auto px-5 xl:max-w-[88rem]">
        {/* Tab Category Title */}
        <CategoryText
          translations={translations}
          sortBy={currTab === "top2" || currTab === "top7" || currTab === "top15" ? "top" : currTab}
          style={currGenerator === "all" ? ["all"] : [currGenerator]}
          pathCode={translations.key}
          queryParam={""}
          contentType={contentType}
        />
        <div className={`z-10 flex items-center`}>
          {/* Hot/New/Top/All Tabs */}
          <div
            ref={scrollContainerRef}
            className="xs:mx-0 mx-[-18px] flex items-center overflow-hidden overflow-x-scroll whitespace-nowrap scrollbar-hide sm:w-full"
          >
            <div className="ml-5 flex">
              <TabLink
                label="hot"
                current={currTab}
                setCurrent={setCurrTab}
                newURL={newURL}
                contentType={contentType}
                currTab={currTab}
                currGenerator={currGenerator}
                queryParam=""
                translations={translations}
              />
            </div>
            <TabLink
              label="new"
              current={currTab}
              setCurrent={setCurrTab}
              newURL={newURL}
              contentType={contentType}
              currTab={currTab}
              currGenerator={currGenerator}
              queryParam=""
              translations={translations}
            />

            {contentType === "gif" && (
              <>
                <TabLink
                  label="top"
                  current={currTab}
                  setCurrent={setCurrTab}
                  newURL={newURL}
                  contentType={contentType}
                  currTab={currTab}
                  currGenerator={currGenerator}
                  queryParam=""
                  translations={translations}
                />
              </>
            )}

            {contentType === "image" && (
              <>
                <TopTabLink
                  label="top"
                  current={currTab}
                  setCurrent={setCurrTab}
                  newURL={newURL}
                  translations={translations}
                  options={options}
                />
                <div className="mr-2.5 inline-block h-4 border-r border-white"></div>

                <TabLink
                  label="all"
                  current={currGenerator}
                  setCurrent={setCurrGeneratorFromStr}
                  isTab={false}
                  newURL={newURL}
                  contentType={contentType}
                  currTab={currTab}
                  currGenerator={currGenerator}
                  queryParam=""
                  translations={translations}
                />

                {/* Styles toggle modal */}
                <StyleToggle
                  styleParam={styleParam}
                  updateStyleParam={updateStyleParam}
                  translations={styleToggleTranslations}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* Scrollable content grid with slider, expand/fullscreen, scroll to top buttons*/}
      <InfiniteScrollWrapper
        contentType={contentType}
        translations={translations}
        modalTranslations={modalTranslations}
        signInModalTranslations={signInModalTranslations}
        currTab={currTab}
        currGenerator={currGenerator}
        constructUrlFn={constructUrl}
      />
    </div>
  );
}
